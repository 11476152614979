import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../config/api.js';
import { useLogger } from '../hooks/useLogger';
import { Eye, EyeOff } from 'lucide-react';
import './css/Profile.css';
import { MARKETING_SUBDOMAIN } from '../config/app';

const Profile = ({ handleLogin, handleLogout, userProfile, handleSubscriptionAction }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const logger = useLogger();

  useEffect(() => {
    if (userProfile) {
      logger.info('Profile > User profile:', userProfile);
      setStatusMessage('');
    }
  }, [userProfile]);

  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await handleLogin(email);
      setStatusMessage(result.message || t('profile.magicLinkSent'));
    } catch (error) {
      setStatusMessage(error.response?.data?.message || t('profile.loginError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="profile-panel">
      {isLoading ? (
        <div className="spinner"></div>
      ) : userProfile ? (
        <>
          <div className="profile-section">
            <div className="profile-info-section">
              <div className="email-section">
                <label className="email-label">{t('profile.email')}:</label>
                <span className="email-address">{userProfile.email}</span>
              </div>
              <div className="logout-section">
                <button onClick={handleLogout} className="logout-button">
                  {t('profile.logout')}
                </button>
              </div>
            </div>
            <div className="subscription-section">
              <h3>{t('profile.subscription')}</h3>
              <button 
                className="subscription-button" 
                onClick={handleSubscriptionAction}
              >
                {t('profile.upgradeSubscription')}
              </button>
              <div className="subscription-info">
                <button onClick={() => window.open(`https://${MARKETING_SUBDOMAIN}/pricing`, '_blank')} className="subscription-learn-more">
                  {t('profile.learnMore')}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>{t('profile.login')}</h3>
          <form onSubmit={onLogin} className="login-form">
            <input
              type="email"
              placeholder={t('profile.email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">{t('profile.requestMagicLink')}</button>
          </form>
          {statusMessage && (
            <p className={`status-message ${statusMessage.includes('error') ? 'error' : 'success'}`}>
              {statusMessage.includes('error') ? `❌ ${statusMessage}` : `✅ ${statusMessage}`}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;


