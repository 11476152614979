import React, { useState, useEffect, useMemo } from 'react';
import { useLogger } from '../hooks/useLogger';
import { useTranslation } from 'react-i18next';
import './css/ParallelTextHistory.css';
import './css/common.css';
// import WordDetailsModal from './WordDetailsModal';
import ProductPromotionModal from './ProductPromotionModal';

const ParallelTextHistory = ({ 
  dyslexicFontEnabled, 
  textAlignment, 
  pageContent,
  pagePronunciation,
  pageFurigana,
  learningLanguage, 
  nativeLanguage, 
  showNativeLanguage,
  showPronunciation,
  showFurigana,
  nativeLanguageDirection,
  learningLanguageDirection
}) => {
  const { t } = useTranslation();
  const logger = useLogger('ParallelTextHistory');
  const [hoverIndices, setHoverIndices] = useState({ sentenceIndex: null, wordIndices: { source: [], target: [] } });
  // const [isWordDetailsModalOpen, setIsWordDetailsModalOpen] = useState(false);
  const [isProductPromotionModalOpen, setIsProductPromotionModalOpen] = useState(false);
  const [selectedWordDetails, setSelectedWordDetails] = useState(null);
  const [toggledWords, setToggledWords] = useState(new Map());
  const [showPopup, setShowPopup] = useState(false);

  const hasValidAssociations = useMemo(() => !!pageContent && !!pageContent.association && pageContent.association.word_maps.length > 0, [pageContent]);
  const hasContent = useMemo(() => !!pageContent && Array.isArray(pageContent.learning_content) && pageContent.learning_content.length > 0, [pageContent]);

  logger.debug(`pageContent: ${JSON.stringify(pageContent)}`);
  logger.debug(`textAlignment: ${textAlignment}`);

  useEffect(() => {
    logger.info(`learningLanguage: ${learningLanguage}`);
    logger.info(`nativeLanguage: ${nativeLanguage}`);
    logger.info(`showNativeLanguage: ${showNativeLanguage}`);
    logger.info(`showPronunciation: ${showPronunciation}`);
    logger.info('pageContent:', pageContent);
  }, [learningLanguage, nativeLanguage, showNativeLanguage, showPronunciation, pageContent, pagePronunciation, logger]);

  useEffect(() => {
    if (!showNativeLanguage) {
      setToggledWords(new Map());
      setShowPopup(true);
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [showNativeLanguage]);

  const handleMouseEnter = (sentenceIndex, wordIndex, isLearning) => {
    if (!hasValidAssociations) return;

    const words = isLearning ? pageContent.learning_content[sentenceIndex].words : pageContent.native_content[sentenceIndex].words;
    if (isPunctuation(words[wordIndex])) return;

    const associationMap = pageContent.association.word_maps[sentenceIndex];
    const isSourceLearning = pageContent.association.source_language === learningLanguage;

    let sourceIndices = new Set();
    let targetIndices = new Set();

    if (isLearning === isSourceLearning) {
      sourceIndices.add(wordIndex.toString());
      const targetIndexes = associationMap.source_to_target[wordIndex.toString()] || [];
      targetIndexes.forEach(idx => targetIndices.add(idx.toString()));
    } else {
      targetIndices.add(wordIndex.toString());
      const sourceIndexes = associationMap.target_to_source[wordIndex.toString()] || [];
      sourceIndexes.forEach(idx => sourceIndices.add(idx.toString()));
    }

    setHoverIndices({
      sentenceIndex: sentenceIndex,
      wordIndices: {
        source: Array.from(sourceIndices),
        target: Array.from(targetIndices)
      }
    });
  };

  const handleMouseLeave = () => {
    if (!hasValidAssociations) return;
    setHoverIndices({ sentenceIndex: null, wordIndices: { source: [], target: [] } });
  };

  const handleWordClick = (sentenceIndex, wordIndex, isLearning) => {
    const words = isLearning ? pageContent.learning_content[sentenceIndex].words : pageContent.native_content[sentenceIndex].words;
    if (isPunctuation(words[wordIndex])) return;

    if (!showNativeLanguage) {
      const key = `${sentenceIndex}-${wordIndex}`;
      setToggledWords(prev => {
        const next = new Map(prev);
        if (next.has(key)) {
          next.delete(key);
        } else {
          next.set(key, true);
        }
        return next;
      });
    }

    const isSourceLearning = pageContent.association.source_language === learningLanguage;
    const sourceSentence = isSourceLearning ? pageContent.learning_content[sentenceIndex] : pageContent.native_content[sentenceIndex];
    const targetSentence = isSourceLearning ? pageContent.native_content[sentenceIndex] : pageContent.learning_content[sentenceIndex];
    
    const sourceWords = sourceSentence.words;
    const targetWords = targetSentence.words;
    
    let clickedWord, clickedWords, otherLanguageWords;

    if (hasValidAssociations) {
      const associationMap = pageContent.association.word_maps[sentenceIndex];
      const highlightedSourceIndices = new Set(hoverIndices.wordIndices.source);
      const highlightedTargetIndices = new Set(hoverIndices.wordIndices.target);

      if (isLearning === isSourceLearning) {
        clickedWord = sourceWords[wordIndex];
        clickedWords = sourceWords.filter((_, index) => highlightedSourceIndices.has(index.toString()));
        otherLanguageWords = targetWords.filter((_, index) => highlightedTargetIndices.has(index.toString()));
      } else {
        clickedWord = targetWords[wordIndex];
        clickedWords = targetWords.filter((_, index) => highlightedTargetIndices.has(index.toString()));
        otherLanguageWords = sourceWords.filter((_, index) => highlightedSourceIndices.has(index.toString()));
      }
    } else {
      clickedWord = isLearning ? sourceWords[wordIndex] : targetWords[wordIndex];
      clickedWords = [clickedWord];
      otherLanguageWords = [];
    }

    clickedWords = [...new Set(clickedWords)];
    otherLanguageWords = [...new Set(otherLanguageWords)];

    // setSelectedWordDetails({
    //   clickedWord,
    //   clickedWords,
    //   otherLanguageWords,
    //   clickedWordLanguageId: isLearning ? learningLanguage : nativeLanguage,
    //   otherLanguageId: isLearning ? nativeLanguage : learningLanguage,
    //   learningLanguage: learningLanguage,
    //   nativeLanguage: nativeLanguage
    // });
    // setIsWordDetailsModalOpen(true);
  };

  const isPunctuation = (word) => {
    return /^[.,!?;:。，！？；：]$/.test(word );
  };

  const renderSentence = (sentence, isLearning, sentenceIndex) => {
    if (!sentence) return null;
    
    const words = sentence.words;
    const isSourceLearning = pageContent.association.source_language === learningLanguage;
    const hasPronunciation = showPronunciation && isLearning && pagePronunciation && pagePronunciation[sentenceIndex];
    const hasFurigana = showFurigana && isLearning && pageFurigana && pageFurigana[sentenceIndex];
    const direction = isLearning ? learningLanguageDirection : nativeLanguageDirection;

    return (
      <div className={`sentence ${hasPronunciation ? 'has-pronunciation' : ''} ${hasFurigana ? 'has-furigana' : ''}`} dir={direction}>
        {words.map((word, wordIndex) => {
          const isPunc = isPunctuation(word);
          const isHighlighted = !isPunc && hasValidAssociations &&
            hoverIndices.sentenceIndex === sentenceIndex && 
            hoverIndices.wordIndices[isLearning === isSourceLearning ? 'source' : 'target'].includes(wordIndex.toString());

          const pronunciation = hasPronunciation && pagePronunciation[sentenceIndex][wordIndex];
          const furigana = hasFurigana && pageFurigana[sentenceIndex][wordIndex];

          const key = `${sentenceIndex}-${wordIndex}`;
          const isToggled = !showNativeLanguage && toggledWords.has(key);
          
          let displayWord = word;
          if (isToggled && isLearning && hasValidAssociations) {
            const associationMap = pageContent.association.word_maps[sentenceIndex];
            const targetIndices = associationMap.target_to_source[wordIndex.toString()] || [];
            const associatedSourceIndices = associationMap.source_to_target[targetIndices[0].toString()] || [];
            if (targetIndices.length > 0) {
              if (associatedSourceIndices.length > 1) {
                displayWord = associatedSourceIndices[0] === wordIndex ? 
                  targetIndices.map(index => pageContent.native_content[sentenceIndex].words[index]).join(' ') :
                  '"'; // use double quote to indicate that the word is associated with the previous word
              } else {
                displayWord = targetIndices.map(index => pageContent.native_content[sentenceIndex].words[index]).join(' ');
                if (displayWord == '') { // handle parts of speech like が which don't have an association
                  displayWord = '*';
                }
              }
            }
          }

          return (
            <span
              key={wordIndex}
              className={`word-wrapper ${isHighlighted ? 'highlight-wrapper' : ''} ${isToggled ? 'toggled' : ''}`}
              onMouseEnter={() => !isPunc && handleMouseEnter(sentenceIndex, wordIndex, isLearning)}
              onMouseLeave={() => !isPunc && handleMouseLeave()}
              onClick={() => !isPunc && handleWordClick(sentenceIndex, wordIndex, isLearning)}
            >
              {isPunc ? (
                <span className="punctuation">{word}</span>
              ) : (
                <ruby className="furigana-wrapper">
                  {displayWord}
                  <rt className="furigana-text">{showFurigana && furigana ? furigana : ''}</rt>
                </ruby>
              )}
              {showPronunciation && pronunciation && !isPunctuation(pronunciation) && displayWord === word && (
                <span className="pronunciation">{pronunciation}</span>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  const renderSentencePair = (learningContent, nativeContent, index) => (
    <div key={index} className={`sentence-pair ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`}>
      <div className="language-one" dir={learningLanguageDirection}>
        {renderSentence(learningContent, true, index)}
      </div>
      {showNativeLanguage && (
        <div className="language-two" dir={nativeLanguageDirection}>
          {renderSentence(nativeContent, false, index)}
        </div>
      )}
    </div>
  );

  if (!hasContent) {
    return <p dir={nativeLanguageDirection}>{t('parallelTextHistory.noContent')}</p>;
  }

  return (
    <div className={`parallel-text-history ${textAlignment} ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} dir={learningLanguageDirection}>
      {pageContent.learning_content.map((sentence, index) => 
        renderSentencePair(sentence, pageContent.native_content[index], index)
      )}
      {/* <WordDetailsModal 
        wordDetails={selectedWordDetails} 
        isOpen={isWordDetailsModalOpen} 
        onClose={() => setIsWordDetailsModalOpen(false)}
        nativeLanguageDirection={nativeLanguageDirection}
        learningLanguageDirection={learningLanguageDirection}
      /> */}
      <ProductPromotionModal
        isOpen={isProductPromotionModalOpen}
        onClose={() => setIsProductPromotionModalOpen(false)}
        nativeLanguageDirection={nativeLanguageDirection}
      />
      {showPopup && (
        <div className="notification-popup">
          <span style={{ whiteSpace: 'pre-line' }}>
            {t('parallelTextHistory.nativeLanguageEnabled')}
          </span>
          <button 
            className="popup-close-button" 
            onClick={() => setShowPopup(false)}
            aria-label={t('common.close')}
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default ParallelTextHistory;