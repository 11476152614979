import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import ParallelTextHistory from './ParallelTextHistory';
import { useLogger } from '../hooks/useLogger';
import './css/ParallelTextViewer.css';
import Toolbar from './Toolbar';
import Menu from './Menu';
import './css/common.css';
import './css/themes.css';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../config/api.js';
import i18n from '../i18n';
// import WordDetailsModal from './WordDetailsModal';
import ThemePickerWelcomeScreen from './ThemePickerWelcomeScreen';
import { useLocation, useNavigate } from 'react-router-dom';

const ParallelTextViewer = ({ languageId, onLanguageChange, openCookiePreferences, magicLinkStatus }) => {
  const { t } = useTranslation();

  const [bookId, setBookId] = useState('');
  const [bookDetails, setBookDetails] = useState(null);
  const [currentPageId, setCurrentPageId] = useState(null);
  const [currentPage, setCurrentPage] = useState("");
  const [pageContent, setPageContent] = useState(null);
  const [pagePronunciation, setPagePronunciation] = useState(null);
  const [pageFurigana, setPageFurigana] = useState(null);
  const [showFurigana, setShowFurigana] = useState(false);
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(true);
  const [hoveredWord, setHoveredWord] = useState(null);

  const [textAlignment, setTextAlignment] = useState(() => {
    return localStorage.getItem('textAlignment') || 'justify-left';
  });
  const [dyslexicFontEnabled, setDyslexicFontEnabled] = useState(() => {
    return localStorage.getItem('dyslexicFontEnabled') === 'true';
  });
  const [nativeLanguage, setNativeLanguage] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('n') || localStorage.getItem('nativeLanguage') || "";
  });
  const [learningLanguage, setLearningLanguage] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('l') || localStorage.getItem('learningLanguage') || "";
  });
  const [nativeLanguageDirection, setNativeLanguageDirection] = useState(() => {
    return localStorage.getItem('nativeLanguageDirection') || 'ltr';
  });
  const [learningLanguageDirection, setLearningLanguageDirection] = useState(() => {
    return localStorage.getItem('learningLanguageDirection') || 'ltr';
  });

  const [isLoadingBook, setIsLoadingBook] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const abortControllerRef = useRef(null);
  const [showNativeLanguage, setShowNativeLanguage] = useState(true);
  const [showPronunciation, setShowPronunciation] = useState(true);
  const [pageAssociations, setPageAssociations] = useState(null);
  const [showThemePicker, setShowThemePicker] = useState(false);
  const [currentTheme, setCurrentTheme] = useState('Classic');
  const [themeLoaded, setThemeLoaded] = useState(false);

  const [selectedWord, setSelectedWord] = useState(null);
  // const [isWordDetailsModalOpen, setIsWordDetailsModalOpen] = useState(false);
  const [selectedWordDetails, setSelectedWordDetails] = useState(null);

  const [userProfile, setUserProfile] = useState(null);

  const [pageNumberInput, setPageNumberInput] = useState('');
  const [isPageNumberChanged, setIsPageNumberChanged] = useState(false);

  const logger = useLogger('ParallelTextViewer');

  const location = useLocation();
  const navigate = useNavigate();

  const [validLanguageOptions, setValidLanguageOptions] = useState([]);

  const [urlParamsProcessed, setUrlParamsProcessed] = useState(false);

  const [initialPageId, setInitialPageId] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const nLang = params.get('n');
    const lLang = params.get('l');
    const bId = params.get('b');
    const pId = params.get('p');

    if (nLang) setNativeLanguage(nLang);
    if (lLang && nLang !== lLang) setLearningLanguage(lLang);
    if (bId) setBookId(bId);
    if (pId) {
      setInitialPageId(pId);
      setCurrentPageId(pId);
    }

    setUrlParamsProcessed(true);
  }, []);

  useEffect(() => {
    if (urlParamsProcessed && !isInitialLoad) {
      updateURL();
    }
  }, [nativeLanguage, learningLanguage, bookId, currentPageId, urlParamsProcessed, isInitialLoad]);

  const updateURL = () => {
    logger.info('Updating URL with:', nativeLanguage, learningLanguage, bookId, currentPageId);
    const params = new URLSearchParams();
    if (nativeLanguage) params.set('n', nativeLanguage);
    if (learningLanguage) params.set('l', learningLanguage);
    if (bookId) params.set('b', bookId);
    if (currentPageId) params.set('p', currentPageId);
    navigate(`?${params.toString()}`, { replace: true });
  };

  const handleLanguageOptionsChange = (options) => {
    logger.info('Language options:', options);
    if (options && Object.keys(options).length > 0) {
      const params = new URLSearchParams(location.search);
      const nLang = params.get('n');
      const lLang = params.get('l');
      const bId = params.get('b');
      const pId = params.get('p');

      logger.info('Params: Native language:', nLang);
      logger.info('Params: Learning language:', lLang);
      logger.info('Params: Book ID:', bId);
      logger.info('Params: Page ID:', pId);

      let nativeLanguageChanged = false;
      let learningLanguageChanged = false;
    
      if (nLang && options[nLang]) {
        setNativeLanguage(nLang);
        setNativeLanguageDirection(options[nLang].direction);
        nativeLanguageChanged = true;
      }
      if (lLang && options[lLang]) {
        setLearningLanguage(lLang);
        setLearningLanguageDirection(options[lLang].direction);
        learningLanguageChanged = true;
      }
      if (bId) {
        setBookId(bId);
      }
      if (pId && bId && (nativeLanguageChanged || learningLanguageChanged)) {
        fetchPage(pId);
      }
    
      setValidLanguageOptions(options);
    }
  }

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await apiRequest('GET', 'USER_PROFILE');
      if (response) {
        logger.info('User profile:', response);
        setUserProfile(response);
      }else{
        logger.info('User profile not found');
        setUserProfile(null);
      }
    } catch (error) {
      logger.error('Error fetching user profile:', error);
    }
  };

  useEffect(() => {
    logger.info('User profile:', userProfile);
  }, [userProfile]);
  
  const handleLogin = () => {
    logger.info('User logged in');
    fetchUserProfile();
  };

  const handleSignup = () => {
    logger.info('User signed up');
    // TODO - show some welcome message to the user
    fetchUserProfile();
  };

  const handleLogout = () => {
    logger.info('User logged out');
    setUserProfile(null);
  };

  const handlePasswordReset = () => {
    logger.info('Password reset');
    setUserProfile(null);
  };

  const ClickableText = ({ text, onWordClick, language }) => {
    const words = text.split(' ');
    return (
      <span className="clickable-text">
        {words.map((word, index) => (
          <span
            key={index}
            className="hoverable-word"
            onClick={() => onWordClick(word)}
          >
            {word}
            {index < words.length - 1 ? ' ' : ''}
          </span>
        ))}
      </span>
    );
  };

  useEffect(() => {
    localStorage.setItem('textAlignment', textAlignment);
  }, [textAlignment]);

  useEffect(() => {
    localStorage.setItem('dyslexicFontEnabled', dyslexicFontEnabled);
  }, [dyslexicFontEnabled]);
  
  useEffect(() => {
    logger.debug('Setting local storage learningLanguage:', learningLanguage);
    localStorage.setItem('learningLanguage', learningLanguage);
  }, [learningLanguage]);
  
  useEffect(() => {
    logger.debug('Setting local storage nativeLanguage:', nativeLanguage);
    localStorage.setItem('nativeLanguage', nativeLanguage);
  }, [nativeLanguage]);

  useEffect(() => {
    localStorage.setItem('nativeLanguageDirection', nativeLanguageDirection);
  }, [nativeLanguageDirection]);

  useEffect(() => {
    localStorage.setItem('learningLanguageDirection', learningLanguageDirection);
  }, [learningLanguageDirection]);
  
  useEffect(() => {
    if (bookId) {
      loadBook();
      setMenuOpen(false);
    }
    
    // Abort any ongoing fetchPage request when bookId, learningLanguage, or nativeLanguage changes
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [bookId, learningLanguage, nativeLanguage]);

  useEffect(() => {
    setPageContent(null);
    setPagePronunciation(null);
    setBookDetails(null);
    setCurrentPageId(null);
    setCurrentPage("");
    setPageAssociations(null);
  }, [learningLanguage, nativeLanguage]);

  useEffect(() => {
    if (pageContent && learningLanguage === 'ja') {
      fetchFurigana();
    }
  }, [pageContent, learningLanguage]);

  const fetchFurigana = async () => {
    try {
      const response = await apiRequest('GET', 'FURIGANA_TRANSCRIPTION', 
        { book_id: bookId, page_id: currentPageId },
        { learning_language: learningLanguage }
      );
      setPageFurigana(response);
    } catch (error) {
      logger.error('Error fetching furigana:', error);
    }
  };

  const toggleFurigana = () => {
    setShowFurigana(prev => !prev);
  };

  const handleThemeSelect = (theme) => {
    logger.debug(`Theme selected: ${theme}`);
    setCurrentTheme(theme);
    document.documentElement.setAttribute('data-theme', theme.toLowerCase());
    localStorage.setItem('selectedTheme', theme);
    setShowThemePicker(false);
  };
  
  const closeThemePicker = () => {
    setShowThemePicker(false);
  };
  
  const loadBook = async () => {
    setIsLoadingBook(true);
    setError('');
    setBookDetails(null);
    setPageContent(null);
    setCurrentPageId(null);
    setPagePronunciation(null);
    
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    try {
      const detailsResponse = await apiRequest('GET', 'BOOK', { book_id: bookId }, { 
        language: learningLanguage,
      });
      setBookDetails(detailsResponse);
      
      if (initialPageId) {
        await fetchPage(initialPageId);
        setInitialPageId(null); // Clear the initial page ID after it's been used
      } else {
        const firstPageResponse = await apiRequest('GET', 'BOOK_FIRST_PAGE', { book_id: bookId });
        await fetchPage(firstPageResponse.page_id);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        logger.debug('Request canceled:', error.message);
      } else {
        setError(t('error.loadBook'));
        logger.error('Error loading book:', error);
      }
    } finally {
      setIsLoadingBook(false);
      setIsInitialLoad(false);
    }
  };

  const fetchPage = async (pageId) => {
    setIsLoadingPage(true);
    
    abortControllerRef.current = new AbortController();
    
    try {
      logger.debug('Fetching page:', pageId);
      const response = await apiRequest(
        'GET',
        'BOOK_PAGE', 
        { 
          book_id: bookId,
          page_id: pageId
        },
        {
          native_language: nativeLanguage,
          learning_language: learningLanguage
        },
        null,
        abortControllerRef.current.signal
      );
      
      setPageContent(response);
      setCurrentPage(response.page_number);
      setCurrentPageId(pageId);
      fetchPronunciation(pageId);
      
      if (!isInitialLoad) {
        updateURL();
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        logger.debug('Request canceled:', error.message);
      } else {
        setError(t('error.fetchPage'));
        logger.error('Error fetching page:', error);
      }
    } finally {
      setIsLoadingPage(false);
    }
  };

  const fetchPronunciation = async (pageId) => {
    try {
      const response = await apiRequest('GET', 'BOOK_PAGE_PRONUNCIATION', 
        { book_id: bookId, page_id: pageId }, 
        { native_language: nativeLanguage, learning_language: learningLanguage }
      );
      setPagePronunciation(response);
    } catch (error) {
      if (axios.isCancel(error)) {
        logger.debug('Pronunciation request canceled:', error.message);
      } else {
        setError(t('error.fetchPronunciation'));
        logger.error('Error fetching pronunciation:', error);
      }
    }
  };

  const handlePreviousPage = async () => {
    try {
      const response = await apiRequest('GET', 'BOOK_PREVIOUS_PAGE', { book_id: bookId, page_id: currentPageId });
      if (response.previous_page_id) {
        await fetchPage(response.previous_page_id);
      } else {
        setError(t('error.firstPage'));
        setTimeout(() => setError(''), 1500);
      }
    } catch (error) {
      setError(t('error.fetchPreviousPage'));
      logger.error('Error fetching previous page:', error);
    }
  };

  const handleNextPage = async () => {
    try {
      const response = await apiRequest('GET', 'BOOK_NEXT_PAGE', { book_id: bookId, page_id: currentPageId });
      if (response.next_page_id) {
        await fetchPage(response.next_page_id);
      } else {
        setError(t('error.lastPage'));
        setTimeout(() => setError(''), 1500);
      }
    } catch (error) {
      setError(t('error.fetchNextPage'));
      logger.error('Error fetching next page:', error);
    }
  };

  const handleBookSelect = (selectedBookId, selectedNativeLanguage, selectedLearningLanguage) => {
    // Abort any ongoing fetchPage request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    setBookId(selectedBookId);
    setNativeLanguage(selectedNativeLanguage);
    setLearningLanguage(selectedLearningLanguage);
    onLanguageChange(selectedNativeLanguage);
  };

  const handleLanguageSelect = (newNativeLanguage, newLearningLanguage, nativeLanguageDirection, learningLanguageDirection) => {
    if (newNativeLanguage !== nativeLanguage || newLearningLanguage !== learningLanguage || nativeLanguageDirection !== nativeLanguageDirection || learningLanguageDirection !== learningLanguageDirection) {
      // Abort any ongoing fetchPage request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      
      logger.debug(`Native language: ${newNativeLanguage}, Learning language: ${newLearningLanguage}`);
      setLearningLanguage(newLearningLanguage);
      setLearningLanguageDirection(learningLanguageDirection);
      setNativeLanguage(newNativeLanguage);
      setNativeLanguageDirection(nativeLanguageDirection);
    
      i18n.changeLanguage(newNativeLanguage);
      
      onLanguageChange(newNativeLanguage);

      // Reset book and page content
      setBookId('');
      setPageContent(null);
      setPagePronunciation(null);
      setBookDetails(null);
      setCurrentPageId(null);
      setCurrentPage("");
      setPageAssociations(null);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleTextAlignmentChange = (event) => {
    const newAlignment = event.target.value;
    setTextAlignment(newAlignment);
    localStorage.setItem('textAlignment', newAlignment);
  };
  
  const handleDyslexicFontToggle = () => {
    const newValue = !dyslexicFontEnabled;
    setDyslexicFontEnabled(newValue);
    localStorage.setItem('dyslexicFontEnabled', newValue);
  };

  const toggleNativeLanguageVisibility = () => {
    setShowNativeLanguage(!showNativeLanguage);
  };

  const togglePronunciationVisibility = () => {
    setShowPronunciation(prev => !prev);
  };
  
  // const closeWordDetailsModal = () => {
  //   setIsWordDetailsModalOpen(false);
  //   setSelectedWord(null);
  // };

  useEffect(() => {
    const savedTheme = localStorage.getItem('selectedTheme');
    logger.debug('Loaded saved theme:', savedTheme);
    if (savedTheme) {
      setCurrentTheme(savedTheme);
      setShowThemePicker(false);
      document.documentElement.setAttribute('data-theme', savedTheme.toLowerCase());
    } else {
      setShowThemePicker(true);
    }
    setThemeLoaded(true);
  }, []);

  const handleWordHover = (word) => {
    setHoveredWord(word);
  };

  // const handleWordClick = (word, language) => {
  //   setSelectedWordDetails({
  //     clickedWord: word,
  //     clickedWords: [word],
  //     otherLanguageWords: [],
  //     clickedWordLanguageId: language,
  //     otherLanguageId: language === learningLanguage ? nativeLanguage : learningLanguage,
  //     learningLanguage: learningLanguage,
  //     nativeLanguage: nativeLanguage
  //   });
  //   setIsWordDetailsModalOpen(true);
  // };

  const renderBookTitle = () => {
    logger.info('Book details:', bookDetails);
    if (!bookDetails || !bookDetails.title || !bookDetails.title_words) return null;
  
    const title = bookDetails.title;
    const titleWords = bookDetails.title_words;
    const isSpaceSeparated = title.includes(' ');
  
    let currentIndex = 0;
    return (
      <div className="book-title">
        {titleWords.map((word, index) => {
          const startIndex = title.indexOf(word, currentIndex);
          const endIndex = startIndex + word.length;
          const displayText = title.slice(startIndex, endIndex);
  
          currentIndex = endIndex;
  
          return (
            <React.Fragment key={index}>
              <span
                className={`title-word`}
                // className={`title-word ${hoveredWord === word ? 'hovered' : ''}`}
                // onMouseEnter={() => handleWordHover(word)}
                // onMouseLeave={() => handleWordHover(null)}
                // onClick={() => handleWordClick(word)}
              >
                {displayText}
              </span>
              {isSpaceSeparated && index < titleWords.length - 1 && (
                <span className="title-space"> </span>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };
  
  useEffect(() => {
    if (currentPage) {
      setPageNumberInput(currentPage.toString());
      setIsPageNumberChanged(false);
    }
  }, [currentPage]);

  const handlePageNumberChange = (e) => {
    const value = e.target.value;
    setPageNumberInput(value);
    setIsPageNumberChanged(value !== currentPage.toString());
  };

  const handlePageNumberBlur = () => {
    if (!pageNumberInput) {
      setPageNumberInput(currentPage.toString());
      setIsPageNumberChanged(false);
    }
  };

  const handleJumpToPage = async () => {
    if (!bookId || !pageNumberInput) return;

    try {
      const response = await apiRequest('GET', 'BOOK_PAGE_BY_NUMBER', 
        { book_id: bookId },
        { page_number: pageNumberInput }
      );
      if (response && response.page_id) {
        await fetchPage(response.page_id);
        setIsPageNumberChanged(false);
      } else {
        setError(t('error.invalidPageNumber'));
        setTimeout(() => setError(''), 1500);
      }
    } catch (error) {
      setError(t('error.fetchPageByNumber'));
      logger.error('Error fetching page by number:', error);
    }
  };

  useEffect(() => {
    if (nativeLanguageDirection === 'rtl') {
      document.documentElement.style.setProperty('--menu-toggle-right', 'auto');
      document.documentElement.style.setProperty('--menu-toggle-left', '10px');
    } else {
      document.documentElement.style.setProperty('--menu-toggle-right', '10px');
      document.documentElement.style.setProperty('--menu-toggle-left', 'auto');
    }
  }, [nativeLanguageDirection]);

  useEffect(() => {
    if (magicLinkStatus === 'success') {
      logger.info('Magic link login successful, fetching user profile');
      fetchUserProfile();
    }
  }, [magicLinkStatus]);

  return (
    <div className={`parallel-text-container ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} dir={nativeLanguageDirection}>
        {showThemePicker && (
          <ThemePickerWelcomeScreen
          onThemeSelect={handleThemeSelect}
          onClose={closeThemePicker}
          currentTheme={currentTheme}
        />
        )}
        <button className="menu-toggle" onClick={toggleMenu}>☰</button>
        <Menu 
          onBookSelect={handleBookSelect} 
          onLanguageSelect={handleLanguageSelect} 
          open={menuOpen ? 'open' : ''} 
          currentTheme={currentTheme} 
          onThemeChange={handleThemeSelect}
          onLanguageOptionsChange={handleLanguageOptionsChange}
          textAlignment={textAlignment}
          onTextAlignmentChange={handleTextAlignmentChange}
          dyslexicFontEnabled={dyslexicFontEnabled}
          onDyslexicFontToggle={handleDyslexicFontToggle}
          onOpenCookiePreferences={openCookiePreferences}
          initialNativeLanguage={nativeLanguage}
          initialLearningLanguage={learningLanguage}
          userProfile={userProfile}
          onLogin={handleLogin}
          onSignup={handleSignup}
          onLogout={handleLogout}
          onPasswordReset={handlePasswordReset}
          nativeLanguageDirection={nativeLanguageDirection}
        />
        <div className="toolbar-and-content" dir={nativeLanguageDirection}>
        <Toolbar
          onToggleNativeLanguage={toggleNativeLanguageVisibility}
          onTogglePronunciation={togglePronunciationVisibility}
          onToggleFurigana={toggleFurigana}
          bookDetails={bookDetails}
          currentPageId={currentPageId}
          nativeLanguage={nativeLanguage}
          currentPageNumber={currentPage}
          learningLanguage={learningLanguage}
          isContentLoaded={!!pageContent}
          isPronunciationLoaded={!!pagePronunciation}
          isFuriganaLoaded={!!pageFurigana}
          showFurigana={showFurigana}
          nativeLanguageDirection={nativeLanguageDirection}
        />
          <div className={`book-content ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} dir={nativeLanguageDirection}>
            {isLoadingBook ? (
              <div className="loading-animation">
                <div className="spinner"></div>
                <p>{t('loading.book')}</p>
              </div>
            ) : (
              <>
              <div className={`navigation-buttons ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} dir={nativeLanguageDirection}>
              <button onClick={handlePreviousPage} disabled={!bookDetails || currentPage === 1 || isLoadingPage}>{t('navigation.previousPage')}</button>
              {renderBookTitle()}
              <button onClick={handleNextPage} disabled={!bookDetails || currentPage === bookDetails.page_count || isLoadingPage}>{t('navigation.nextPage')}</button>
          </div>
                {pageContent ? (
                  <>
                    {isLoadingPage ? (
                      <div className="loading-animation">
                        <div className="spinner"></div>
                        <p>{t('loading.page')}</p>
                      </div>
                    ) : (
                      <ParallelTextHistory 
                        dyslexicFontEnabled={dyslexicFontEnabled} 
                        textAlignment={textAlignment} 
                        pageContent={pageContent}
                        pageAssociations={pageAssociations}
                        pagePronunciation={pagePronunciation}
                        pageFurigana={pageFurigana}
                        learningLanguage={learningLanguage} 
                        nativeLanguage={nativeLanguage} 
                        showNativeLanguage={showNativeLanguage}
                        showPronunciation={showPronunciation}
                        showFurigana={showFurigana}
                        nativeLanguageDirection={nativeLanguageDirection}
                        learningLanguageDirection={learningLanguageDirection}
                      />
                    )}
                    <div className="page-navigation" dir={nativeLanguageDirection}>
                      <div className="book-page-of-total">
                        {bookDetails ? (
                          <>
                            <input
                              type="text"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              value={pageNumberInput}
                              onChange={handlePageNumberChange}
                              onBlur={handlePageNumberBlur}
                              className="current-page-input"
                            />
                            {t('page.ofTotal')} {bookDetails.page_count}
                          </>
                        ) : ''}
                        <button 
                          className="skip-to-page-button"
                          onClick={handleJumpToPage} 
                          disabled={!isPageNumberChanged}
                        >
                          {t('page.skipTo')}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="no-content" dir={nativeLanguageDirection}>
                    <p>{t('welcome.chooseBook')}</p>
                  </div>
                )}
              </>
            )}
            {error && <p className="error-loading-page" style={{color: 'red'}}>{error}</p>}
          </div>
        </div>
        {/* <WordDetailsModal 
          wordDetails={selectedWordDetails}
          isOpen={isWordDetailsModalOpen}
          onClose={() => setIsWordDetailsModalOpen(false)}
        /> */}
      </div>
  );
};

export default ParallelTextViewer;
