import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { apiRequest, setUserLocaleHeader } from '../config/api.js';
import { useLogger } from '../hooks/useLogger';
import { Heart, Settings as SettingsIcon, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import './css/ParallelTextViewer.css';
import './css/Menu.css';
import './css/common.css';
import Settings from './Settings';
import Profile from './Profile';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Menu = ({ 
  onBookSelect, 
  onLanguageSelect, 
  initialNativeLanguage,
  initialLearningLanguage, 
  open, 
  currentTheme, 
  onThemeChange, 
  textAlignment, 
  onTextAlignmentChange, 
  dyslexicFontEnabled,
  onDyslexicFontToggle, 
  onOpenCookiePreferences, 
  userProfile,
  onLogin,
  onSignup,
  onLogout,
  onPasswordReset,
  onLanguageOptionsChange
}) => {
  const { t, i18n } = useTranslation();
  const [books, setBooks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [difficultyLevel, setDifficultyLevel] = useState('');
  const [sortBy, setSortBy] = useState('difficulty_level');
  const [nativeLanguage, setNativeLanguage] = useState(initialNativeLanguage || '');
  const [learningLanguage, setLearningLanguage] = useState(initialLearningLanguage || '');
  const [search, setSearch] = useState('');
  const [languageOptions, setLanguageOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [favoriteBooks, setFavoriteBooks] = useState([]);
  const [userSubscription, setUserSubscription] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [sidebarWidth, setSidebarWidth] = useState(350);
  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState(false);
  const [textDirection, setTextDirection] = useState('ltr');

  const logger = useLogger('Menu');
  
  // Log only when languages change
  useEffect(() => {
    logger.info('Native language:', nativeLanguage);
    logger.info('Learning language:', learningLanguage);
  }, [nativeLanguage, learningLanguage]);

  // Memoize language options
  const sortedLearningLanguages = useMemo(() => {
    return Object.entries(languageOptions)
      .filter(([id]) => id !== nativeLanguage)
      .sort(([aId, aData], [bId, bData]) => 
        t(`languages.${aId}`).localeCompare(t(`languages.${bId}`), i18n.language)
      )
      .map(([id, data]) => ({ id, ...data }));
  }, [languageOptions, nativeLanguage, t, i18n.language]);

  // Use useCallback for event handlers
  const handleNativeLanguageChange = useCallback((e) => {
    const newNativeLanguage = e.target.value;
    setNativeLanguage(newNativeLanguage);
    if (newNativeLanguage === learningLanguage) {
      const newLearningLanguage = Object.keys(languageOptions).find(id => id !== newNativeLanguage) || '';
      setLearningLanguage(newLearningLanguage);
    }
    i18n.changeLanguage(newNativeLanguage);
    setUserLocaleHeader(newNativeLanguage);
  }, [learningLanguage, languageOptions, i18n, setUserLocaleHeader]);

  const handleLearningLanguageChange = useCallback((e) => {
    const newLearningLanguage = e.target.value;
    setLearningLanguage(newLearningLanguage);
    if (newLearningLanguage === nativeLanguage) {
      const newNativeLanguage = Object.keys(languageOptions).find(id => id !== newLearningLanguage) || '';
      setNativeLanguage(newNativeLanguage);
      i18n.changeLanguage(newNativeLanguage);
    }
  }, [nativeLanguage, languageOptions, i18n]);

  const handleSearchChange = useCallback((e) => {
    logger.debug(`Search: ${e.target.value}`);
    setSearch(e.target.value);
    setPage(1); // Reset to first page when search changes
  }, []);

  const getDifficultyColor = useCallback((level) => {
    const colors = {
      '0': '#66BB6A', // Green for easiest
      '1': '#26C6DA', // Light blue
      '2': '#FFA726', // Orange
      '3': '#5C6BC0', // Blue
      '4': '#AB47BC', // Purple
      '5': '#EC407A'  // Pink for hardest
    };
    return colors[level] || '#9E9E9E';
  }, []);

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsResizing(true);
  }, []);

  const toggleSettings = useCallback(() => {
    setShowSettings(!showSettings);
    setShowProfile(false);
  }, [showSettings]);

  const toggleProfile = useCallback(() => {
    setShowProfile(!showProfile);
    setShowSettings(false);
  }, [showProfile]);

  const fetchUserSubscription = useCallback(async () => {
    try {
      const response = await apiRequest('GET', 'USER_SUBSCRIPTION');
      setUserSubscription(response);
    } catch (error) {
      logger.error('Error fetching user subscription:', error);
    }
  }, [logger]);

  const handleSubscriptionAction = useCallback(async () => {
    logger.info('handleSubscriptionAction');
    logger.info(`stripe public key: ${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
    try {
      const response = await apiRequest('POST', 'CREATE_CHECKOUT_SESSION');
      if (!response || !response.url) {
        throw new Error('Invalid checkout session response');
      }
      // Open in new window with specific dimensions
      const width = 500;
      const height = 700;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2;
      window.open(
        response.url,
        'Stripe Checkout',
        `width=${width},height=${height},left=${left},top=${top},location=yes,menubar=no,toolbar=no,status=no`
      );
    } catch (error) {
      logger.error('Error creating checkout session:', error);
      // You might want to show an error message to the user here
    }
  }, [logger]);

  const handleLogin = useCallback(async (email) => {
    try {
      const response = await apiRequest('POST', 'LOGIN', {}, {}, { email });
      if (response) {
        logger.info('Magic link request successful');
        // You might want to show a success message to the user here
        return { success: true, message: response.message };
      }
    } catch (error) {
      logger.error('Error requesting magic link:', error);
      throw error;
    }
  }, [logger]);

  const handleLogout = useCallback(async () => {
    try {
      const response = await apiRequest('POST', 'LOGOUT');
      if (response) {
        onLogout();
      }
      // Clear other user-related states
      setFavoriteBooks([]);
      setUserSubscription(null);
    } catch (error) {
      logger.error('Error logging out:', error);
    }
  }, [logger, onLogout]);

  useEffect(() => {
    fetchLanguageOptions();
  }, []);

  useEffect(() => {
    if (userProfile) {
      logger.info('User profile:', userProfile);
      fetchFavoriteBooks();
      // fetchUserSubscription();
    }
  }, [userProfile, logger]);

  const getDifficultyLevelValue = useCallback((level) => {
    const difficultyMap = {
      [t('difficulty.beginner')]: 1,
      [t('difficulty.intermediate')]: 2,
      [t('difficulty.advanced')]: 3,
      [t('difficulty.fluent')]: 4
    };
    return difficultyMap[level] || '';
  }, [t]);

  const fetchBooks = useCallback(async () => {
    if (nativeLanguage && learningLanguage) {
      setIsLoading(true);
      try {
        const response = await apiRequest('GET', 'BOOKS', {}, {
          page,
          per_page: 2,
          difficulty_level: getDifficultyLevelValue(difficultyLevel),
          sort_by: sortBy,
          native_language: nativeLanguage,
          search: search
        });
        logger.debug('Books fetched:', response);
        setBooks(response.books);
        setTotalPages(response.total_pages);

        // Ensure current page is not greater than total pages
        if (page > response.total_pages) {
          setPage(Math.max(1, response.total_pages));
        }
      } catch (error) {
        logger.error('Error fetching books:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [nativeLanguage, learningLanguage, page, difficultyLevel, sortBy, search, logger, getDifficultyLevelValue]);

  useEffect(() => {
    fetchBooks();
  }, [page, difficultyLevel, sortBy, nativeLanguage, search]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isResizing) return;
      let newWidth;
      if (textDirection === 'rtl') {
        // For RTL, calculate width from the right edge of the window
        newWidth = Math.min(
          Math.max(200, window.innerWidth - e.clientX),
          window.innerWidth * 0.8
        );
      } else {
        // For LTR, calculate width from the left edge of the sidebar
        newWidth = Math.min(
          Math.max(200, e.clientX - sidebarRef.current.getBoundingClientRect().left),
          window.innerWidth * 0.8
        );
      }
      setSidebarWidth(newWidth);
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, textDirection]);

  const fetchFavoriteBooks = useCallback(async () => {
    // try {
    //   const response = await apiRequest('GET', 'USER_FAVORITES', {}, { user_id: 'current_user_id' });
    //   setFavoriteBooks(response.map(book => book.id));
    // } catch (error) {
    //   logger.error('Error fetching favorite books:', error);
    // }
  }, [logger]);

  const handleFavoriteClick = useCallback(async (bookId, event) => {
    logger.debug('Favorite clicked:', bookId);
    event.stopPropagation();
    // try {
    //   if (favoriteBooks.includes(bookId)) {
    //     await apiRequest('DELETE', 'USER_FAVORITE', { book_id: bookId }, { user_id: 'current_user_id' });
    //     setFavoriteBooks(favoriteBooks.filter(id => id !== bookId));
    //   } else {
    //     await apiRequest('POST', 'USER_FAVORITES', {}, { user_id: 'current_user_id', book_id: bookId });
    //     setFavoriteBooks([...favoriteBooks, bookId]);
    //   }
    // } catch (error) {
    //   logger.error('Error updating favorite status:', error);
    // }
  }, [favoriteBooks, logger]);

  useEffect(() => {
    if (Object.keys(languageOptions).length > 0 && isInitialLoad) {
      detectUserLocale();
      onLanguageOptionsChange(languageOptions);
    }
  }, [languageOptions, isInitialLoad, onLanguageOptionsChange]);

  const detectUserLocale = useCallback(() => {
    logger.info('Detecting user locale');
    if (!nativeLanguage) {
      const browserLang = navigator.language.split('-')[0];
      const detectedLanguage = Object.keys(languageOptions).find(id => id === browserLang) || Object.keys(languageOptions)[0];
      logger.info('Detected user language:', detectedLanguage);
      setNativeLanguage(detectedLanguage);
      setUserLocaleHeader(detectedLanguage);
      i18n.changeLanguage(detectedLanguage);
  
      if (!learningLanguage) {
        const defaultLearning = Object.keys(languageOptions).find(id => id !== detectedLanguage) || Object.keys(languageOptions)[1];
        setLearningLanguage(defaultLearning);
      }
    }else{
      i18n.changeLanguage(nativeLanguage);
      setUserLocaleHeader(nativeLanguage);
    }
    setIsInitialLoad(false);
  }, [nativeLanguage, learningLanguage, languageOptions, i18n, logger, setUserLocaleHeader]);

  const fetchLanguageOptions = useCallback(async () => {
    try {
      const response = await apiRequest('GET', 'LANGUAGE_OPTIONS');
      logger.info('Got language options:', response);
      setLanguageOptions(response);
    } catch (error) {
      logger.error('Error fetching language options:', error);
    }
  }, [logger]);

  useEffect(() => {
    if (!isInitialLoad) {
      const nativeLanguageDirection = languageOptions[nativeLanguage]?.direction || 'ltr';
      const learningLanguageDirection = languageOptions[learningLanguage]?.direction || 'ltr';
      onLanguageSelect(nativeLanguage, learningLanguage, nativeLanguageDirection, learningLanguageDirection);
      // Update text direction based on native language
      setTextDirection(nativeLanguageDirection);
    }
  }, [nativeLanguage, learningLanguage, isInitialLoad, languageOptions, onLanguageSelect]);

  const handleBookClick = useCallback((bookId) => {
    onBookSelect(bookId, nativeLanguage, learningLanguage);
  }, [onBookSelect, nativeLanguage, learningLanguage]);

  const handlePageChange = useCallback((newPage) => {
    setPage(Math.min(Math.max(1, newPage), totalPages));
  }, [totalPages]);

  return (
    <div 
      className={`books-browser ${open} ${isResizing ? 'resizing' : ''}`}
      ref={sidebarRef}
      style={{ width: `${sidebarWidth}px`, direction: textDirection }}
    >
      <div className="books-browser-header">
        <User
          className={`profile-icon ${showProfile ? 'active' : ''}`}
          onClick={toggleProfile}
          size={24}
          color="var(--text-color)"
        />
        <p 
          className="books-browser-title" 
          onClick={() => window.location.href = '/'} 
          style={{ cursor: 'pointer' }}
        >
          {t('menu.title')}
        </p>
        <SettingsIcon
          className={`settings-icon ${showSettings ? 'active' : ''}`}
          onClick={toggleSettings}
          size={24}
          color="var(--text-color)"
        />
      </div>
      {showSettings ? (
        <Settings
          currentTheme={currentTheme}
          onThemeChange={onThemeChange}
          textAlignment={textAlignment}
          onTextAlignmentChange={onTextAlignmentChange}
          dyslexicFontEnabled={dyslexicFontEnabled}
          onDyslexicFontToggle={onDyslexicFontToggle}
          onOpenCookiePreferences={onOpenCookiePreferences}
          userSubscription={userSubscription}
          handleSubscriptionAction={handleSubscriptionAction}
        />
      ) : showProfile ? (
        <Profile
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          handleSubscriptionAction={handleSubscriptionAction}
          signupErrorMessage={signupErrorMessage}
          userProfile={userProfile}
        />
      ) : (
        <>
          <div className="language-selectors">
            <div className="language-selector">
              <label htmlFor="native-language">{t('menu.nativeLanguage')}</label>
              <select
                id="native-language"
                value={nativeLanguage}
                onChange={handleNativeLanguageChange}
                className="filter-select"
              >
                {Object.entries(languageOptions).map(([id, data]) => (
                  <option key={id} value={id}>{data.name}</option>
                ))}
              </select>
            </div>
            <div className="language-selector">
              <label htmlFor="learning-language">{t('menu.learningLanguage')}</label>
              <select
                id="learning-language"
                value={learningLanguage}
                onChange={handleLearningLanguageChange}
                className="filter-select"
              >
                {sortedLearningLanguages.map(lang => (
                  <option key={lang.id} value={lang.id}>{t(`languages.${lang.id}`)}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="books-browser-subtitle">
            <label>{t('menu.pick_a_book')}</label>
          </div>
          <div className="search-bar">
            <input
              type="text"
              placeholder={t('menu.search')}
              value={search}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>
          <div className="books-filters">
            <select 
              value={difficultyLevel} 
              onChange={(e) => setDifficultyLevel(e.target.value)}
              className="filter-select"
            >
              <option value="">
                {difficultyLevel ? t('menu.removeFilter') : t('menu.filterByLevel')}
              </option>
              <option value={t('difficulty.beginner')}>{t('difficulty.beginner')}</option>
              <option value={t('difficulty.intermediate')}>{t('difficulty.intermediate')}</option>
              <option value={t('difficulty.advanced')}>{t('difficulty.advanced')}</option>
              <option value={t('difficulty.fluent')}>{t('difficulty.fluent')}</option>
            </select>
            <select 
              value={sortBy} 
              onChange={(e) => setSortBy(e.target.value)}
              className="filter-select"
            >
              <option value="difficulty_level">{t('menu.sortByLevel')}</option>
              <option value="title">{t('menu.sortByTitle')}</option>
            </select>
          </div>
          <div className="books-list">
            {isLoading ? (
              <div className="loading-animation">
                <div className="spinner"></div>
                <p>{t('menu.loadingBooks')}</p>
              </div>
            ) : (
              books.map((book) => (
                <div key={book.id} onClick={() => handleBookClick(book.id)} className="book-card">
                  <h3 className="book-title">{book.title}</h3>
                  <div className="book-meta">
                    <p className="book-summary">{book.summary}</p>
                  </div>
                  <div className="book-stats">
                    <span 
                      className="difficulty-badge" 
                      style={{backgroundColor: getDifficultyColor(book.difficulty_level)}}
                    >
                      {book.difficulty_level}
                    </span>
                    {/* <Heart
                      className={`favorite-icon ${favoriteBooks.includes(book.id) ? 'favorite' : ''}`}
                      onClick={(e) => handleFavoriteClick(book.id, e)}
                      size={24}
                      color={favoriteBooks.includes(book.id) ? 'red' : 'gray'}
                      fill={favoriteBooks.includes(book.id) ? 'red' : 'none'}
                    /> */}
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="pagination">
            <button 
              onClick={() => handlePageChange(page - 1)} 
              disabled={page === 1}
              className="pagination-button"
            >
              {textDirection === 'rtl' ? '▶' : '◀'}
            </button>
            <span className="page-indicator">
              {t('menu.pageIndicator', { currentPage: Math.min(page, totalPages), total: totalPages })}
            </span>
            <button 
              onClick={() => handlePageChange(page + 1)} 
              disabled={page >= totalPages}
              className="pagination-button"
            >
              {textDirection === 'rtl' ? '◀' : '▶'}
            </button>
          </div>
        </>
      )}
      <div 
        className={`resize-handle ${textDirection === 'rtl' ? 'rtl' : ''}`}
        onMouseDown={handleMouseDown}
      />
    </div>
  );
};

export default React.memo(Menu);
